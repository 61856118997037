import React, {SyntheticEvent, useMemo, useState} from 'react';
import {AvailableCoupon, CustomerDashboardSnippetProfile, ISyntheticEvent} from "../Helpers/Types";
import QRCode from "react-qr-code";
import {use} from "msw/lib/types/utils/internal/requestHandlerUtils";
import ClickToRedeemWarning from "./ClickToRedeemWarning";
import {Simulate} from "react-dom/test-utils";
import cancel = Simulate.cancel;

type DashboardCouponDisplayProps = {
    coupon: AvailableCoupon
    apply: (code: string) => void
    onCompleteExists: boolean
    config?: CustomerDashboardSnippetProfile
}

export default function DashboardCouponDisplay({coupon, apply, config, onCompleteExists}: DashboardCouponDisplayProps) {
    const [flipped, setFlipped] = useState(false)
    const [showWarning, setShowWarning] = useState(false)
    const showCode = useMemo(() => config?.ShowCouponCode ?? false, [config])
    const showQR = useMemo(() => config?.ShowCouponQRCode ?? false, [config])
    const applyLabel = useMemo(() => config?.ApplyCouponLabel ?? false, [config])
    const clickToRedeem = useMemo(() => config?.EnableClickToRedeem ?? false, [config])
    const allowApply = useMemo(() => clickToRedeem, [onCompleteExists, clickToRedeem])
    
    const flip = (e: ISyntheticEvent) => {
        e.preventDefault();
        if (!clickToRedeem)
            setFlipped(!flipped)
        else
            setShowWarning(true)
    }
    
    const applyCoupon = (e: SyntheticEvent) => {
        e.preventDefault()
        apply(coupon.Code)
    }
    
    const cancel = () => {
        setShowWarning(false)
    }
    
    const confirm = () => {
        apply(coupon.Code)
        setFlipped(!flipped)
        setShowWarning(false)
    }
    
    return (
        <>
            {showWarning && <ClickToRedeemWarning cancel={cancel} confirm={confirm} title={config?.CouponConfirmationTitle ?? "Click-To-Redeem™"} body={config?.CouponConfirmationText ?? "This will mark the coupon as redeemed, only confirm if you are ready to use the coupon now"} />}
            <div className={`bl-dashboard-coupon bLoyal-center ${clickToRedeem ? '' : 'clickable'}`} onClick={clickToRedeem ? undefined : flip}>
                {!flipped &&
                    <>
                        <p className='bl-coupon-name bLoyal-center'>{coupon.Name}</p>
                        {showCode && <p className='bl-coupon-code bLoyal-center'>{coupon.Code}</p>}
                        {coupon.QuantityAvailable !== null &&
                            <>
                                <br />
                                <p>x{coupon.QuantityAvailable}</p>
                            </>
                        }
                        {allowApply && <button className='bl-snippet-button' style={{padding: '4px 8px'}} onClick={flip}>{applyLabel ? applyLabel : 'Apply'}</button>}
                    </>
                }
                {flipped &&
                    <>
                        <p className='bl-coupon-name bLoyal-center'>{coupon.Name}</p>
                        {/*@ts-ignore*/}
                        {showQR && <QRCode value={coupon.Code} size={100}/>}
                        {showCode && <p className='bl-coupon-code bLoyal-center' style={{marginTop: '8px'}}>{coupon.Code}</p>}
                        
                    </>
                }
            </div>
        </>
    )
}