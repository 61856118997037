import React, {useEffect, useMemo, useState} from 'react';
import Iframe from 'react-iframe';
import {useAuth} from "./Utils/UseAuth";
import {useSnippetContext} from "./Utils/UseSnippetContext";
import useServiceUrls from "./Utils/UseServiceUrls";

type UsaePayAddCreditCardFrameProps = {
	savePayment: Function,
	paymentFormUrl: string
}

export default function UsaePayAddCreditCardFrame(props: UsaePayAddCreditCardFrameProps) {
	// @ts-ignore
	const { session } = useAuth();
	// @ts-ignore
	const { redirectToHome, domain } = useSnippetContext()
	const {serviceUrls} = useServiceUrls(domain)
	const [cardSaved, setCardSaved] = useState(false)
	
	// Removing the trailing '/' because usaepay does not parse the query parameters properly with it on the url
	const url = useMemo(() => {
		let formUrl = props.paymentFormUrl
		while (formUrl.charAt(formUrl.length - 1) === '/')
			formUrl = formUrl.substr(0, formUrl.length - 1)
		
		return formUrl
	}, [props.paymentFormUrl])
	
	const snippetUrl = useMemo(() => {
		const serverUrl = serviceUrls?.WebSnippetsApiUrl
		return serverUrl.toLowerCase().replace('https://websnippets', 'https://snippets')
	}, [serviceUrls])
	
	const redirectUrl = useMemo(() => {
		return `${snippetUrl}/snippetredirect?blSessionKey=${session}`
	}, [snippetUrl])
	
	const readMessage = (event: any) => {
		// IMPORTANT: check the origin of the data!
		console.log(event.origin)
		console.log(snippetUrl)
		if (event.origin === snippetUrl) {
			console.log('received origin')
			try {
				const data = JSON.parse(event.data)
				if (data?.Token !== undefined) {
					if (!cardSaved) {
						props.savePayment(data.Token, data.CardNumber, data.CardType)
						setCardSaved(true)
					}
				}
			} catch (e) {
				console.log(e)
			}
		}
		return;
	}
	
	const delayedReadMessage = (event: any) => {
		console.log(event)
		setTimeout(() => readMessage(event), 600)
	}

	useEffect(() => {
		window.addEventListener('message', delayedReadMessage);
		return () => {
			window.removeEventListener('message', delayedReadMessage, false);
		};
	}, []);

	// const checkRef = () => {
	// 	setRefCheckCounter(refCheckCounter + 1)
	//	
	// 	if (refCheckCounter === 0)
	// 		return;
	// 	const iframe = document?.getElementsByTagName('iframe')[0];
	// 	if (iframe === undefined || iframe === null)
	// 		return
	//	
	// 	try {
	// 		// @ts-ignore
	// 		const location = iframe.contentWindow.location
	// 		if (location !== null) {
	// 			const queryString = require('query-string')
	// 			const parsed = queryString.parse(location.search)
	// 			let cardType = parsed.UMcardType;
	// 			cardType = mapCreditCardType(cardType);
	// 			props.savePayment( parsed.UMcardRef, parsed.UMmaskedCardNum, cardType)
	// 		}
	// 	} catch (err) {
	// 		console.error(err)
	// 	}
	// };
	
	if (props.paymentFormUrl === '')
		return (
			<p>The payment provider is unavailable</p>
		)

	return (
		<div style={{ height: '500px' }} id='payment-website'>
			{redirectUrl &&
				<>	
					{/*@ts-ignore*/}
					<Iframe
						url={`${url}?UMcommand=cc:save&UMredirApproved=${redirectUrl}`}
						width="100%"
						id="myId"
						className="myClassname"
						height="100%"
						frameBorder={0}
					/>
				</>
			}
		</div>
	);
}