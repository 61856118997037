import React, {Fragment, useMemo} from 'react';
import {
    ChoiceResponse,
    Club,
    ClubBillingPeriod,
    ClubPreferenceSnippetProfile,
    CustomFieldDisplayType, CustomFieldOption,
    ICustomFieldType, ISelectOption, RadioOption
} from "../../Helpers/Types";
import NumberInput from "../../NumberInput";
import RadioSelection from "./RadioSelection";
import SelectInput from "../../SelectInput";
import TextInput from "../../TextInput";
import {createOptions, createStartDateOptions, getPaymentStrategyOptions} from "../../Helpers/Functions";
import ClubBillingPeriodSelection from "./ClubBillingPeriodSelection";
import GiftChoice from "./GiftChoice";

type ClubDetailsProps = {
    deviceProfile: any
    handleChange: (field: string, value: any) => void
    handleCustomFieldChange: (field: string, value: any) => void
    customCodes: Array<{code: string, value: string}>
    snippetProfile: ClubPreferenceSnippetProfile
    club: Club
    clubTerm: string
    period: ClubBillingPeriod,
    startDate: string,
    isGift: ChoiceResponse
    selectWidth: number,
    paymentStrategy: string
}

export default function ClubDetails(props: ClubDetailsProps) {

    const mapCustomFieldOption = (option: CustomFieldOption, index: number) => {
        const code = option.CustomFieldCode;
        const field = props.deviceProfile.ClubMembershipCustomFields.find((d: any) => d.Code === code)

        if (!field)
            return null;

        const mapListOptions = (options: Array<any>) => {
            let listOptions: Array<ISelectOption> = []
            options.forEach((listOption: any) => {
                if (option.CustomFieldValues === null || option.CustomFieldValues.includes(listOption.Value) || option.CustomFieldValues.includes('All'))
                    listOptions.push({
                        text: listOption.Name,
                        value: listOption.Value,
                    })
            })
            return listOptions
        }

        const mapRadioOptions = (options: Array<any>) => {
            let radioOptions: Array<RadioOption> = []
            options.forEach((radioOption: any) => {
                if (option.CustomFieldValues === null || option.CustomFieldValues.includes(radioOption.Value) || option.CustomFieldValues.includes('All'))
                    radioOptions.push({
                    label: radioOption.Name,
                    value: radioOption.Value,
                })
            })
            return radioOptions
        }

        let value = props.customCodes.find((curr: any) => curr.code === code)?.value
        switch (field.FieldType) {

            case ICustomFieldType.Int:
            case ICustomFieldType.Decimal:
                return (
                    <Fragment key={index}>
                        {/*@ts-ignore*/}
                        <NumberInput id={field.Code} value={value ?? ''} label={field.Name}
                                     handleChange={props.handleCustomFieldChange}/>
                        <br/>
                    </Fragment>
                )
            case ICustomFieldType.ValidatedList:
                if (props.snippetProfile?.CustomFieldsDisplayType === CustomFieldDisplayType.Radio)
                    return (
                        <Fragment key={index}>
                            <RadioSelection  fieldName={field.Code} value={value} handleChange={props.handleCustomFieldChange} helpText={field.HelpText} label={field.Name} options={mapRadioOptions(field.ValidValues)}/>
                        </Fragment>
                    )

                return (
                    <Fragment key={index}>
                        <SelectInput options={mapListOptions(field.ValidValues)} label={field.Name} current={value ?? ""} fieldName={field.Code} handleChange={props.handleCustomFieldChange} disabled={false} required={field.IsRequired} width={props.selectWidth === 0 ? undefined : props.selectWidth}/>
                    </Fragment>
                )
            case ICustomFieldType.Money:
            case ICustomFieldType.Date:
            case ICustomFieldType.Unknown:
            case ICustomFieldType.Time:
            case ICustomFieldType.DateTime:
            case ICustomFieldType.Bool:
            case ICustomFieldType.Byte:
            case ICustomFieldType.Blob:
            case ICustomFieldType.List:
            case ICustomFieldType.MultiSelectValidatedList:
            case ICustomFieldType.String:
            default:

                return (
                    <Fragment key={index}>

                        <TextInput id={field.Code} value={value ?? ''} label={field.Name}
                                   handleChange={props.handleCustomFieldChange}/>
                        <br/>
                    </Fragment>
                )
        }
    }

    const quantityOptions = useMemo(() => createOptions((props.club?.ClubTermAmount ?? 0) === 0 ? 10 : props.club?.ClubTermAmount ?? 10), [props.club])
    const startDateOptions = useMemo(() => createStartDateOptions(props.snippetProfile.MaxMonthsAheadForStartDate), [props.snippetProfile])
    const paymentStrategyOptions = useMemo(() => getPaymentStrategyOptions(), [])

    return (
        <>

            {props.snippetProfile.AllowGift && <GiftChoice label={props.snippetProfile.GiftLabel ? props.snippetProfile.GiftLabel : "Is this a gift?"}
                                                          helpText={props.snippetProfile.GiftHelpText} value={props.isGift}
                                                          handleChange={props.handleChange}/>}
            {props.club.ClubTermOptions !== 'NoExpiration' && <SelectInput options={quantityOptions}
                                                                     label={props.snippetProfile.ClubTermLabel ? props.snippetProfile.ClubTermLabel : "Choose the # of shipments to give"}
                                                                     current={props.clubTerm}
                                                                     fieldName={'clubTerm'}
                                                                     handleChange={props.handleChange} disabled={false} width={props.selectWidth === 0 ? undefined : props.selectWidth}/>}
            {props.snippetProfile.AllowCustomStartDate &&
                <SelectInput options={startDateOptions} label={props.snippetProfile.StartDateLabel ? props.snippetProfile.StartDateLabel : "Choose the first shipment date"}
                             current={props.startDate} fieldName={'membershipStartDate'} handleChange={props.handleChange}
                             disabled={false} width={props.selectWidth === 0 ? undefined : props.selectWidth}/> }
            {/*{props.snippetProfile.AllowPaymentStrategy &&*/}
            {/*    <SelectInput options={paymentStrategyOptions} label={props.snippetProfile.PaymentStrategyLabel ? props.snippetProfile.PaymentStrategyLabel : "How would you like to pay?"}*/}
            {/*                 current={props.paymentStrategy} fieldName={'clubPaymentStrategy'} handleChange={props.handleChange}*/}
            {/*                 disabled={false} width={props.selectWidth === 0 ? undefined : props.selectWidth}/> }*/}
            {props.snippetProfile.AllowCustomBillingPeriod &&
                <ClubBillingPeriodSelection label={props.snippetProfile.BillingPeriodLabel ? props.snippetProfile.BillingPeriodLabel : "How often should the shipments be delivered"}
                                            // @ts-ignore
                                            helpText={""} value={props.period} handleChange={props.handleChange} clubPaymentOptions={props.club.ClubMemberBillingPeriodOptions === 0 ? '' : props.club.ClubMemberBillingPeriodOptions} snippetProfile={props.snippetProfile}/>}
            {props.club.PreferenceCustomFieldOptions.map(mapCustomFieldOption)}

        </>
    )
}