import React, {useEffect, useMemo, useState} from 'react';
import SelectInput from "../SelectInput";
import NumberInput from "../NumberInput";
import {ClubSignupProductConfiguration, EGiftCard, EGiftCardSnippetProfile, ProductPreference} from "../Helpers/Types";
import {getInputField, safeConvertStringToNumber} from "../Helpers/Functions";
import {Grid} from "@mui/material";

type GiftAmountProps = {
    product: any
    config: ClubSignupProductConfiguration
    updatePrice: Function
    currencySymbol: string,
}


const getNumber = (stringValue: string, defaultValue: number) => {
    var num = parseInt(stringValue)
    if (isNaN(num))
        return defaultValue

    return num
}

export default function ProductAmountSelection(props: GiftAmountProps) {
    // @ts-ignore
    const [selectedAmount, setSelectedAmount] = useState(props.product.BasePrice?.toString() ?? props.config?.AmountOptions?.[0] ?? "0");
    // @ts-ignore
    const [customAmount, setCustomAmount] = useState(props.product.BasePrice ?? props.config?.MinValue ?? 0);

    const options = useMemo(() => props.config?.AmountOptions?.map(opt => {
        return {text: opt === 'Custom' ? opt : `$${opt}`, value: opt}
    }) ?? [], [props.config?.AmountOptions]);

    useEffect(() => {
        if (props.product.BasePrice && props.config.AmountOptions && props.config.AmountOptions.includes(props.product.BasePrice.toString()) && selectedAmount !== 'Custom')
            setSelectedAmount(props.product.BasePrice.toString())
        else if (props.product.BasePrice && props.config.AmountOptions && props.config.AmountOptions.includes("Custom")) {
            setSelectedAmount('Custom')
            setCustomAmount(props.product.BasePrice ?? 0)
        } 
            // setSelectedAmount(props.config?.AmountOptions?.[0] ?? "0")
    }, [props.product.Price]);

    const handleSelectChange = (field: string, value: string) => {
        if (value === 'Custom') {
            setSelectedAmount(value)
            return;
        }
        
        const valueAsNumber = safeConvertStringToNumber(value)
        if (valueAsNumber !== null) {
            props.updatePrice(props.config, valueAsNumber)
            setSelectedAmount(value)
        }
    }

    const handleCustomAmountChange = (field: string, value: number) => {
        props.updatePrice(props.config, value)
        setCustomAmount(value)
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={selectedAmount === 'Custom' ? 6 : 12} style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                    <div style={{}} >
                        <SelectInput
                            style={{float: 'right'}}
                            label={''}
                            options={options} current={selectedAmount} disabled={false}
                            fieldName='GiftAmount' handleChange={handleSelectChange} removeWidth={true} isTableInput={true}/>
                    </div>
                </Grid>
                {selectedAmount === 'Custom' &&
                    <Grid item sm={6} xs={12}>
                        <div>
                            <p style={{margin: '0'}}>{`(${props.config?.MaxValue !== null && props.config?.MaxValue !== undefined ? `${props.currencySymbol}${props.config?.MinValue ?? 0}-${props.currencySymbol}${props.config.MaxValue}` : `- Minimum: ${props.currencySymbol}${props.config?.MinValue ?? 0}`})`}</p>
                            <NumberInput id={'CustomAmount'} value={customAmount}
                                 label={''}
                                 handleChange={handleCustomAmountChange}
                                 max={props.config?.MaxValue ?? undefined}
                                 min={props.config?.MinValue} showError={true} className='bL-club-product_custom-price' isTableInput={true} width={70}/>
                        </div>
                    </Grid>
                }
            </Grid>
        </>
    )
}