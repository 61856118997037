import React from 'react';
import LoadingSpinner from "../LoadingSpinner";

type SnippetRedirectProps = {

}

export default function SnippetRedirect(props: SnippetRedirectProps) {

    return (
        <div className={'center'}>
            <LoadingSpinner />
        </div>
    )
}