import React, {SyntheticEvent, useEffect} from 'react';
import {WebSnippetProfile} from "../Helpers/Types";
import {useSnippetContext} from "../Utils/UseSnippetContext";
import useGetAlert from "../Utils/UseGetAlert";
import useServiceUrls from "../Utils/UseServiceUrls";
import SnippetHeader from "../Helpers/SnippetHeader";
import SnippetFooter from "../Helpers/SnippetFooter";
import useAcknowledgeAlert from "../Utils/UseAcknowledgeAlert";
import {Button} from "@material-ui/core";
import LoadingSpinner from "../LoadingSpinner";
import {closeWindow} from "../Helpers/Functions";

type AlertSnippetProps = {
    profile: WebSnippetProfile
}

export default function AlertSnippet(props: AlertSnippetProps) {
    // @ts-ignore
    const { domain, deviceCode, cartUid, cartExternalId, cartSourceExternalId, lineUid, alertUid, onCompleteFunction } = useSnippetContext();
    const { serviceUrls } = useServiceUrls(domain);
    const {alert, alertStatus, alertError} = useGetAlert(serviceUrls, domain, deviceCode, alertUid)
    
    const [acknowledgeAlert, {status: acknowledgeStatus, error}] = useAcknowledgeAlert()

    useEffect(() => {
        if (alertStatus === 'success')
            closeWindow()
    }, [alertStatus])
    
    const acknowledge = (e: SyntheticEvent) => {
        e.preventDefault()
        acknowledgeAlert({urls: serviceUrls, domain, deviceCode, cartUid, cartExternalId, cartSourceExternalId, alertUid})
    }
    
    return (
        <div className='bLoyal-center'>
            <SnippetHeader logoUrl={props.profile.LogoUrl} title={props.profile.Title} message={props.profile.Message} snippetCode={props.profile.Code}/>
            {alert && <p>{`${alert.Message}${alert.Detail ? `: ${alert.Detail}` : ''}`}</p>}
            <Button onClick={acknowledge} >OK</Button>
            {alertStatus === 'loading' && <LoadingSpinner />}
            {alertError && <p className='bLoyal-error-text'>{alertError}</p>}
            <SnippetFooter footer={props.profile.Footer} snippetCode={props.profile.Code}/>
        </div>
    )
}