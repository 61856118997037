import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import SnippetRedirect from "./SnippetRedirect";

type SnippetRouterProps = {

}

export default function SnippetRouter(props: SnippetRouterProps) {

    return (
        <BrowserRouter>
            <Route path='/snippetredirect' component={SnippetRedirect} />
        </BrowserRouter>
    )
}