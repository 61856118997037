import React, {SyntheticEvent} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

type ClickToRedeemWarningProps = {
    cancel: () => void;
    confirm: () => void;
    title: string;
    body: string;
}

export default function ClickToRedeemWarning({cancel, confirm, title, body}: ClickToRedeemWarningProps) {

    const close = (e: SyntheticEvent) => {
        e.preventDefault();
        cancel()
    }

    const confirmRedeem = (e: SyntheticEvent) => {
        e.preventDefault()
        confirm()
    }

    return (
        <Dialog className={'bLoyal-dialog'} maxWidth='sm' fullWidth={true} open={true}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent className='dialog-body bLoyal-center'>
                {body}
            </DialogContent>
            <DialogActions>
                <button style={{float: 'left'}} onClick={close}>
                    Close
                </button>
                <button style={{float: 'right'}} onClick={confirmRedeem}>
                    Confirm
                </button>
            </DialogActions>
        </Dialog>
    )
}