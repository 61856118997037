import React, {SyntheticEvent, useMemo, useState} from 'react';
import {
    ClubSignupProductConfiguration,
    ClubSignupSnippetProfile,
    ProductPreference,
    ProductPreferenceType, QuantityType,
    WebSnippetProfile
} from "../Helpers/Types";
import useServiceUrls from "../Utils/UseServiceUrls";
import useProducts from "../Utils/UseProducts";
import {useAuth} from "../Utils/UseAuth";
import ProductSelection from "../ProductSelection";
import LoadingSpinner from "../LoadingSpinner";
import {useSnippetContext} from "../Utils/UseSnippetContext";
import {getPercentOffPrice, safeConvertStringToNumber} from "../Helpers/Functions";

type ClubSignupProductsPageProps = {
    snippetConfig: ClubSignupSnippetProfile
    next: () => void
    nextLabel: string
    back: () => void
    backLabel?: string
    updateField: (field: string, value: any) => void
    productPreferences: Array<ProductPreference>,
    defaultsSet?: boolean
}

export default function ClubSignupProductsPage(props: ClubSignupProductsPageProps) {
    const minimumPrice = props.snippetConfig.MinimumPrice ?? 0;
    const minimumQuantity = props.snippetConfig.MinimumQuantity ?? 0;
    const preferenceType = props.snippetConfig.ProductPreferenceType
    // @ts-ignore
    const {session} = useAuth();
    // @ts-ignore
    const {domain, deviceCode} = useSnippetContext();
    const { serviceUrls } = useServiceUrls(domain);
    const configProducts: Array<ClubSignupProductConfiguration> = useMemo(() => props.snippetConfig.Products, [props.snippetConfig])
    const {productsStatus, products, productsError} = useProducts(serviceUrls, session, domain, deviceCode, configProducts.map((product) => product.Code));
    const [quantity, setQuantity] = useState(0);
    const [price, setPrice] = useState(0.00);
    const [selectedProducts, setSelectedProducts] = useState<Array<ProductPreference>>(props.productPreferences);
    const [requirementsError, setRequirementsError] = useState(false);
    const [defaultsSet, setDefaultsSet] = useState(props.defaultsSet == null ? false : props.defaultsSet);

    const getQuantityType = (quantity: string): QuantityType => {
        const hasCommas = quantity.includes(',');
        if (!hasCommas) {
            if (quantity.includes('#'))
                return QuantityType.Unlimited;
            return QuantityType.Max;
        }
        return QuantityType.Options;
    };

    const getQuantityOptions = (quantity: string) => {
        let options: any = quantity.split(',');
        options = options
            .filter((option: string) => option.trim() !== '')
            .map((option: string) => {
            let trimmed = option.trim();
            return {
                text: trimmed,
                value: parseInt(trimmed)
            };
        });
        return options;
    };

    function getBasePrice(configProduct: any, product: any, selectedProduct?: {
        ProductUid: string | undefined;
        Quantity: number | undefined;
        Price?: number | undefined
    } | ProductPreference) {
        const promptForPriceDefault = parseFloat(configProduct.AmountOptions?.[0])
        const defaultAmount = configProduct.PromptForPrice ? isNaN(promptForPriceDefault) ? product.BasePrice : promptForPriceDefault : product.BasePrice;
        return configProduct.PromptForPrice && selectedProduct?.Price ? selectedProduct.Price : selectedProduct?.Price ? selectedProduct.Price : defaultAmount;
    }

    const mapProductQuantities = (product: any) => {
        let configProduct: any = configProducts.find((prod: any) => prod.Code === product.Code);
        if (!configProduct)
            return product;

        const quantityType = getQuantityType(configProduct.Quantity);
        const selectedProduct = selectedProducts.find((prod: any) => prod.ProductUid === product.Uid);
        const quantityValue: any = quantityType === QuantityType.Options ? getQuantityOptions(configProduct.Quantity) : configProduct.Quantity;
        let needsUpdate = false;
        if (quantityType === QuantityType.Options && quantityValue.length === 1) {
            needsUpdate = true;
        }
        const quantity = selectedProduct ? selectedProduct.Quantity : defaultsSet && !needsUpdate ? 0 : parseDefaultQuantity(configProduct.DefaultQuantity, quantityType, quantityValue)
        return {
            ...product,
            QuantityValue: quantityValue,
            QuantityType: quantityType,
            SelectedQuantity: quantity,
            PercentOff: configProduct.PercentOff,
            ProductUrl: configProduct.ProductUrl,
            BasePrice: getBasePrice(configProduct, product, selectedProduct) 
        };
    } 

    const addQuantityToProductsList = (): Array<any> => {
        if (products === undefined)
            return []
        
        let productsWithQuantities: any;
        productsWithQuantities = products.map((product: any) => mapProductQuantities(product));

        const totalQuantity = productsWithQuantities.reduce((qty: number, p: any) => qty + p.SelectedQuantity, 0);
        let totalPrice = 0.00 
        productsWithQuantities.forEach((p: any) => {
            return totalPrice += getPercentOffPrice(p)
        });
        
        if (price !== totalPrice) {
            setPrice(totalPrice);
        }
        if (quantity !== totalQuantity) {
            setQuantity(totalQuantity);
        }
        
        return productsWithQuantities;
    };
    
    
    const parseDefaultQuantity = (defaultQuantity: any, quantityType: QuantityType, quantityValue: Array<any>): number => {
        const value = parseInt(defaultQuantity);
        if (isNaN(value)) {
            switch (quantityType) {
                case QuantityType.Max:
                    return 0;
                case QuantityType.Options:
                    return quantityValue[0].value;
                default:
                    return 0;
            }
        }
        
        if (quantityType === QuantityType.Options && !quantityValue.some(v => v.value === value))
            return quantityValue[0].value;
        
        return value;
    }

    const productQuantities = useMemo(() => addQuantityToProductsList(), [products, selectedProducts, configProducts])

    const getSelectedProducts = (products: Array<ProductPreference>) => {
        setSelectedProducts(products);
        props.updateField('Products', products)
    };
    
    const valuesUpdated = () => {
        setDefaultsSet(true)
        setRequirementsError(false);
    }

    const saveAndContinue = (e: SyntheticEvent) => {
        e.preventDefault()
        if (price < minimumPrice || quantity < minimumQuantity) {
            setRequirementsError(true);
            return;
        }
        props.updateField('ProductPreferenceType',preferenceType);
        props.updateField('ProductPreferences', selectedProducts);
        props.next();
    };

    return (
        <>
            <form onSubmit={saveAndContinue}>
                <div className='center form'>
                    <h2>{props.snippetConfig.ProductPreferenceCaption ?? "Select the products for your club membership"}</h2>
                    {products &&
                    <>
                        {minimumPrice > 0.0 &&
                        <p>Minimum Price Requirement: {minimumPrice}</p>
                        }
                        {minimumQuantity > 0 &&
                        <p>Minimum Quantity Requirement: {minimumQuantity}</p>
                        }
                        <ProductSelection getValue={getSelectedProducts} products={productQuantities}
                                          setQuantity={setQuantity}
                                          setPrice={setPrice}
                                          valuesUpdated={valuesUpdated} productConfigs={props.snippetConfig.Products}/>
                    </>
                    }
                    {productsStatus === 'loading' &&
                    <LoadingSpinner/>
                    }
                    {props.backLabel !== undefined && <button className='bl-snippet-button' id='back' onClick={props.back}>{props.backLabel}</button>}
                    <button className='bl-snippet-button' id='continue' type='submit' >{props.nextLabel}</button>
                    {productsError &&
                    <p className='error-text'>{productsError}</p>
                    }
                    {requirementsError &&
                    <p className='error-text'>{requirementsError}</p>
                    }
                </div>
            </form>

        </>
    );
}