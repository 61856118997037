import React, {Fragment, SyntheticEvent, useEffect, useReducer, useState} from 'react';
import {
	DeliveryType,
	ICustomer,
	ISyntheticEvent,
	IWizardPage,
	IWizardState,
	MembershipType,
	PaymentSource,
	ProductPreferenceType,
	Status
} from './Helpers/Types';
import ProductsPage from './ProductsPage';
import ShippingPreferencePage from './ShippingPreferencePage';
import PaymentEditor from './PaymentEditor';
import MemberContactInfo from './MemberContactInfo';
import GiftMembershipRecipient from './GiftMembershipRecipient';
import GiftMembershipShippingPreferences from './GiftMembershipShippingPreferences';
import {useAuth} from './Utils/UseAuth';
import {useSnippetContext} from './Utils/UseSnippetContext';
import useServiceUrls from './Utils/UseServiceUrls';
import useJoinClub from './Utils/UseJoinClub';
import {editFormField, WizardAction, WizardActionTypes} from './WizardActions';
import {mapServiceUrls} from './Helpers/Functions';
import {Box, Step, StepLabel, Stepper} from "@material-ui/core";
import useWindowDimensions from "./Hooks/useWindowDimensions";

const wizardState: IWizardState = {
	FormData: {
		Fulfillment: {
			ShippingAddressUid: '',
			PickupLocationUid: '',
			DeliveryType: DeliveryType.Ship
		},
		Payment: {
			PaymentMethodUid: '',
			Source: PaymentSource.Default
		},
		ProductPreferences: [],
		ProductPreferenceType: ProductPreferenceType.Default,
		MemberProductPreferenceType: ProductPreferenceType.Default,
		CustomFields: {}
	},
	GiftRecipient: {},
	GiftRecipientSessionKey: '',
	MembershipType: MembershipType.Standard,
	Membership: {},
	Status: Status.Loading,
	PageNum: 0,
	HasSkippedMemberContact: false,
	HasSetPayment: false,
	HasSetDefaultQuantities: false,
};

export const wizardReducer = (state = wizardState, action: WizardAction): IWizardState => {
	let updatedState: IWizardState;
	switch (action.type) {
		case WizardActionTypes.editFormField:
			if (action.fieldName === 'CustomFields') {
				updatedState = {
					...state,
					FormData: {
						...state.FormData,
						CustomFields: action.value,
					}
				}
			}
			else if (action.fieldName === 'HasSkippedMemberContact') {
				sessionStorage.setItem('blSkippedContact', action.value);
				updatedState = {
					...state,
					HasSkippedMemberContact: action.value === 'true'
				};
			}
			else if (action.fieldName === 'HasSetDefaultQuantities') {
				sessionStorage.setItem('blSkippedContact', action.value);
				updatedState = {
					...state,
					HasSetDefaultQuantities: action.value === 'true'
				};
			}
			else if (action.fieldName === 'GiftRecipient') {
				sessionStorage.setItem('blGiftRecipient', JSON.stringify(action.value));
				updatedState = {
					...state,
					GiftRecipient: action.value
				};
			}
			else if (action.fieldName === 'GiftRecipientSessionKey') {
				sessionStorage.setItem('blGiftRecipientSessionKey', action.value);
				updatedState = {
					...state,
					GiftRecipientSessionKey: action.value
				};
			}
			else {
				updatedState = {
					...state,
					FormData: {
						...state.FormData,
						[action.fieldName]: action.value
					},
					HasSetPayment: action.fieldName === 'Payment'
				};
			}
			break;
		case WizardActionTypes.changeStatus:
			updatedState = {
				...state,
				Status: action.status
			};
			break;
		case WizardActionTypes.changePageNum:
			updatedState = {
				...state,
				PageNum: action.num
			};
			break;
		default:
			return state;
	}
	
	return updatedState;
};

type ClubSignupWizardProps = {
	pageMap: any,
	config: any,
	clubMembership: any,
	customer: ICustomer | null
	backToTypeSelection: (() => void) | null
	clubUid: string,
	membershipType: MembershipType
}

export default function ClubSignupWizard(props: ClubSignupWizardProps) {
	const {width} = useWindowDimensions()
	const [step, setStep] = useState<number>(0)
	const numPages = props.pageMap.length;
	const [joinError, setJoinError] = useState('')
	const [, reloadPage] = useState()

	let initialState: IWizardState = {
		...wizardState,
		FormData: {
			...wizardState.FormData,
			Fulfillment: props.clubMembership.Fulfillment,
			Payment: props.clubMembership.Payment,
			ProductPreferenceType: props.config.MemberSelectionType,
			ProductPreferences: props.clubMembership.ProductPreferences,
			MemberProductPreferenceType: props.clubMembership.ProductPreferenceType,
			CustomFields: props.clubMembership.CustomFields,
		},
		GiftRecipientSessionKey: sessionStorage.getItem('blGiftRecipientSessionKey') ?? '',
		GiftRecipient: JSON.parse(sessionStorage.getItem('blGiftRecipient') ?? '{"Customer": "Empty"}'),
		MembershipType: props.membershipType,
		Membership: props.clubMembership,
		HasSkippedMemberContact: false,
	};
	const [state, dispatch] = useReducer(wizardReducer, initialState);

	// @ts-ignore
	const { session, customer } = useAuth();
	// @ts-ignore
	const { domain, deviceCode } = useSnippetContext();
	const { serviceUrls } = useServiceUrls(domain);
	const [joinClub, { status, error, reset }] = useJoinClub();

	useEffect(() => {
		if (props.pageMap[step] === 'contact')
			if (!state.HasSkippedMemberContact && props.customer !== null) {
				updateField('HasSkippedMemberContact', 'true');
				nextStep({
					target: false, preventDefault: () => {
					}, persist: () => {}
				});
			}
	}, [step]);
	
	useEffect(() => {
		if (status === 'error') {
			// @ts-ignore
			if (error.includes('Customer cannot modify this membership'))
				setJoinError('This customer is already a member of this club, you cannot gift them a membership.')
			else {
				// @ts-ignore
				setJoinError(error)
			}
		}
	})

	const nextStep = (e: ISyntheticEvent) => {
		e.preventDefault();
		try {
			if (step === numPages - 1) {
				if (state.MembershipType === MembershipType.Gift) {
					sessionStorage.removeItem('blGiftRecipient')
					sessionStorage.removeItem('blGiftRecipientSessionKey')
				}
				let customerUid;
				let sponsorUid;
				let paymentSource;
				if (state.MembershipType === MembershipType.Gift) {
					customerUid = state.GiftRecipient.Uid;
					sponsorUid = customer.Uid;
					paymentSource = PaymentSource.Sponsor;
				} else {
					if (state.FormData.Payment.Source === PaymentSource.Sponsor) {
						customerUid = customer.Uid;
						sponsorUid = props.clubMembership.Sponsor?.Uid;
						paymentSource = PaymentSource.Sponsor;
					} else {
						customerUid = customer.Uid;
						sponsorUid = null;
						paymentSource = PaymentSource.Default;
					}
				}
				
				joinClub({
					data: {
						...state.FormData,
						Payment: {
							...state.FormData.Payment,
							Source: paymentSource
						},
						Fulfillment: props.config.IncludeShippingPreferences ? state.FormData.Fulfillment : null,
						CustomerUid: customerUid,
						SponsorUid: sponsorUid,
						
					},
					deviceCode: deviceCode,
					domain: domain,
					session: session,
					urls: mapServiceUrls(serviceUrls),
					clubUid: props.clubUid
				});
			}
			setStep(step + 1)
		} catch (err) {

		}
	};

	const prevStep = (e: ISyntheticEvent) => {
		e.preventDefault();
		if (step === 0) {
			if (props.backToTypeSelection)
				props.backToTypeSelection();
			return;
		}
		try {
			setStep(step - 1)
		} catch (err) {
		}
		// }
	};
	
	const acknowledgeError = (e: SyntheticEvent) => {
		setJoinError('')
		reset()
		prevStep(e)
	}

	const updateField = (field: string, value: any) => {
		// @ts-ignore
		dispatch(editFormField(field, value));
	};
	
	const updateCustomFields = (value: object) => {
		// @ts-ignore
		dispatch(editFormField('CustomFields', value))
	}

 	if (status === 'success') {
		if (props.config.IsReturnSuccessUrl)
			window.location.assign(props.config.SuccessUrlOrSnippet)
		
		return (
			<>
				<h1>{props.config.SuccessMessage ? props.config.SuccessMessage : "Thank you!"}</h1>
			</>
		);
	}

	const mapFullPage = (page: IWizardPage, index: number) => {
		return (
			<Fragment key={index}>
				{step === index &&
                <>
					{mapPage(page)}
                </>
				}
			</Fragment>
		);
	};

	const mapPage = (page: IWizardPage) => {
		if (page.Type === 'products')
			return (
				<>
					<h1 id='bloyal-club-signup-page-title'>
						{page.Title}
					</h1>
					<ProductsPage defaultsSet={state.HasSetDefaultQuantities} productPreferences={state.FormData.ProductPreferences} memberPreferenceType={state.FormData.MemberProductPreferenceType} productPreferenceType={state.FormData.ProductPreferenceType}
					              updateField={updateField} snippetConfig={props.config} prevStep={prevStep} nextStep={nextStep}/>
				</>
			);

		if (page.Type === 'shipping')
			return (
				<>
					<h1 id='bloyal-club-signup-page-title'>
						{page.Title}
					</h1>

					<ShippingPreferencePage updateField={updateField} fulfillment={state.FormData.Fulfillment} prevStep={prevStep} nextStep={nextStep}
					                        config={props.config} customer={customer} sessionKey={session} isGift={false}/>

				</>
			);

		if (page.Type === 'payments')
			return (
				<>
					<h1 id='bloyal-club-signup-page-title'>{page.Title}</h1>
					<PaymentEditor updateField={updateField} payment={state.FormData.Payment} prevStep={prevStep} nextStep={nextStep} isGift={state.MembershipType === MembershipType.Gift}
					               config={props.config} sponsor={props.clubMembership.Sponsor} sponsoredPayment={props.clubMembership.Payment?.Source === PaymentSource.Sponsor ? props.clubMembership.Payment : null}/>
				</>
			);

		if (page.Type === 'contact')
			return (
				<>
					<h1 id='bloyal-club-signup-page-title'>
						{page.Title}
					</h1>
					<MemberContactInfo fields={props.config.ClubMemberContactFields} customFieldDefinitions={props.config.CustomCodeFields} customer={props.customer} nextStep={nextStep}
					                   prevStep={prevStep} customFields={state.FormData.CustomFields} updateCustomFields={updateCustomFields}/>
				</>
			);

		if (page.Type === 'giftRecipient')
			return (
				<>
					<h1 id='bloyal-club-signup-page-title'>
						{page.Title}
					</h1>
					<GiftMembershipRecipient updateField={updateField} nextStep={nextStep} prevStep={prevStep} customFieldDefinitions={props.config.CustomCodeFields}
					                         snippetCode={props.config.GiftMemberSignupSnippetCode} customFields={state.FormData.CustomFields} updateCustomFields={updateCustomFields}/>
				</>
			);

		if (page.Type === 'giftShipping')
			return (
				<>
					<h1 id='bloyal-club-signup-page-title'>
						{page.Title}
					</h1>

					<ShippingPreferencePage updateField={updateField} fulfillment={state.FormData.Fulfillment} prevStep={prevStep} nextStep={nextStep}
											config={props.config} customer={state.GiftRecipient} sessionKey={state.GiftRecipientSessionKey} isGift={true}/>

				</>
			);
	};

	return (
		<div style={{ paddingTop: '20px' }}>
			{/*@ts-ignore*/}
			<Box sx={{width: '100%'}}>
				{width > 550 ?
					<Stepper activeStep={step} alternativeLabel>
						{props.pageMap.map((page: IWizardPage) => (
							// @ts-ignore
							<Step key={page.Title}>
								<StepLabel>{page.Title}</StepLabel>
							</Step>
						))}
					</Stepper> : null}
				{width <=  550 ?
					<Stepper activeStep={step} orientation="vertical">
						{props.pageMap.map((page: IWizardPage) => (
							// @ts-ignore
							<Step key={page.Title}>
								<StepLabel>{page.Title}</StepLabel>
							</Step>
						))}
					</Stepper> : null}
			</Box>
			{props.pageMap.map(mapFullPage)}
			{joinError !== '' && 
			<>
				<p className='bLoyal-error-text'>{joinError}</p>
				<button className='bl-snippet-button' onClick={acknowledgeError} >Back</button>
			</>
			}
		</div>
	);
};